function restarFechasYHumanizar(fechaInicio, fechaFin, langAux = '') {
  const fechaInicioParseada = new Date(fechaInicio);
  const diferencia = fechaFin - fechaInicioParseada;
  const segundos = Math.floor(diferencia / 1000);

  const segundosEnUnMinuto = 60;
  const segundosEnUnaHora = 60 * segundosEnUnMinuto;
  const segundosEnUnDia = 24 * segundosEnUnaHora;
  if ((typeof lang != 'undefined' && lang == 'en') || langAux != '' && langAux == 'en') {
    if (segundos < segundosEnUnMinuto) {
        return `${segundos} second${segundos > 1 ? 's': ''} ago`;
    } else if (segundos < segundosEnUnaHora) {
        const minutos = Math.floor(segundos / segundosEnUnMinuto);
        return `${minutos} minute${minutos > 1 ? 's': ''} ago`;
    } else{
        const horas = Math.floor(segundos / segundosEnUnaHora);
        return `${horas} hour${horas > 1 ? 's': ''} ago`;
    }
  } else {
    if (segundos < segundosEnUnMinuto) {
        return `Hace ${segundos} segundo${segundos > 1 ? 's' : ''}`;
    } else if (segundos < segundosEnUnaHora) {
        const minutos = Math.floor(segundos / segundosEnUnMinuto);
        return `Hace ${minutos} minuto${minutos > 1 ? 's' : ''}`;
    } else{
        const horas = Math.floor(segundos / segundosEnUnaHora);
        return `Hace ${horas} hora${horas > 1 ? 's' : ''}`;
    }
  }

}

if (typeof window != 'undefined') {
  window.restarFechasYHumanizar = restarFechasYHumanizar;
} else {
  module.exports = {
    restarFechasYHumanizar
  }
}
